<template>
  <div id="app" :class="{'filter-visible': this.$store.getters.isFiltersMobileExpanded}">
    <app-header></app-header>
    <router-view/>
    <app-footer></app-footer>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>



<script>
import GlobalHeader from "@/components/GlobalHeader/";
import MainFooter from "@/components/MainFooter/";


export default {
  name: "App",
  data: function() {
    return {};
  },
  computed: {
  },
  components: {
    "app-header": GlobalHeader,
    "app-footer": MainFooter
  },
  methods: {
  },
  created() {
    
  },
  mounted(){
    window.scrollTo(0, 0);
  }
};
</script>
