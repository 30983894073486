const state = {
  combustible: [],
  filteredCombustibleAgg: null,
};

const mutations = {
  TOGGLE_COMBUSTIBLE(state, payload) {
    if (!state.combustible.includes(payload)) {
      state.combustible.push(payload);
    } else {
      state.combustible = state.combustible.filter((item) => item !== payload);
    }
  },
  REMOVE_ALL_COMBUSTIBLE(state) {
    state.combustible = [];
  },
  UPDATE_FILTER_COMBUSTIBLEAGG(state, payload) {
    state.filteredCombustibleAgg = payload;
  },
};

const actions = {
  toggleCombustible: ({ commit }, payload) => {
    commit("TOGGLE_COMBUSTIBLE", payload);
  },
  deleteAllCombustible: ({ commit }, payload) => {
    commit("REMOVE_ALL_COMBUSTIBLE", payload);
  },
  updateFilteredCombustibleAgg: ({ commit }, payload) => {
    commit("UPDATE_FILTER_COMBUSTIBLEAGG", payload);
  },
};

const getters = {
  combustible: (state) => {
    return state.combustible;
  },
  getFilterCombustibleConfig: (state) => {
    let o = [];
    for (let i = 0; i < state.combustible.length; i++) {
      let filterVal = "";

      switch (state.combustible[i]) {
        case "diesel":
          filterVal = "DIESEL";
          break;

        case "electric-petrol":
          filterVal = "PETROL_ELECTRIC";
          break;

        case "petrol":
          filterVal = "PETROL";
          break;

        case "electric":
          filterVal = "PURE_ELECTRIC";
          break;
      }

      if (filterVal) {
        console.log("ADD FILTER " + filterVal);
        o.push({
          value: filterVal,
        });
      }
    }
    return o;
  },
  getFilteredCombustibleAgg: (state) => {
    return state.filteredCombustibleAgg;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
