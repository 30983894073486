const getMinRange = (nMinimo) => {
  return Math.floor( nMinimo  / 1000) * 1000;
} 
const getMaxRange = (nMaximo) => {
  return Math.ceil( (nMaximo) / 1000) * 1000;
} 

const state = {
  minPrecio: 0,
  maxPrecio: 300000,
  minPrecioVisible: 0,
  maxPrecioVisible: 300000,
  filteredPrecioAgg: null
};

const mutations = {
  SETUP_LIMITES_INICIALES(state, payload){
    //precios max/min stock total
    state.minPrecio = getMinRange(payload.minTotal);
    state.maxPrecio = getMaxRange(payload.maxTotal);
  },
  UPDATE_FILTERED_PRICES(state, payload){
    // actualiza con valores enviados desde el slider
    state.minPrecioVisible = getMinRange(Number(payload.min));
    state.maxPrecioVisible = getMaxRange(Number(payload.max));
  },
  REMOVE_ALL_PRECIOS(state){
    // actualiza con valores enviados desde el slider
    state.minPrecioVisible = state.minPrecio;
    state.maxPrecioVisible = state.maxPrecio;
  }
};

const actions = {
  setupInitialPrices: ({ commit }, payload) => {
    commit("SETUP_LIMITES_INICIALES", payload);
  },
  setFilteredPrices: ({ commit }, payload) => {
    commit("UPDATE_FILTERED_PRICES", payload);
  },
  deleteAllPrecio : ({ commit }, payload) => {
    commit("REMOVE_ALL_PRECIOS", payload);
  }
};

const getters = {
  minPrecio: state => {
    return state.minPrecio;
  },
  maxPrecio: state => {
    return state.maxPrecio;
  },
  minPrecioVisible: state => {
    return state.minPrecioVisible;
  },
  maxPrecioVisible: state => {
    return state.maxPrecioVisible;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
  