'use strict';

const signals = require('signals');
import { gsap } from 'gsap';
import lscache from 'lscache';


let _singleton = null;

let cookieName = "volvostock_cookies";

class Cookies {

    constructor (data){
        if(!_singleton) {
            this.data = data
            _singleton = this;
            this.init(data);
        }else{
            return _singleton;
            // console.log("Singleton class created")
        }    
    }

    init (opts){

        this.$el = opts.$el;
        this.$btnOk = opts.$btnOk;

        this.events = {
            COOKIES_ACEPTADAS: new signals.Signal()
        };

        this.bindedMethods = {
            onClickButtonOk : this._onClickButtonOk.bind( this ),
        };


        //si tiene las cookies aceptadas desactiva el div
        this.isAccepted = this.checkCookiePrivacy();
        if(this.isAccepted){
            this.$el.style.display = "none";
        } else {
            this.$el.style.display = "";
        }
        

        
        // this._setIntro();
        this._addListeners();
    }

    show(delay = 0){
        if(!this.isAccepted){
            gsap.to(this.$el,{
                duration: 1,
                opacity:1,
                delay:delay,
                onStart:()=>{
                    this.$el.style.display = 'block';
                }
            });
        }
    }
    
    checkCookiePrivacy(){
        return lscache.get(cookieName) == "yes"?true:false;
    }

    _close(){
        gsap.to(this.$el, {
            duration: .4,
            opacity:0,
            onComplete:()=>{
                this.$el.style.display = "none";
            }
        })
    }
    _onClickButtonOk(){
        //save cookies
        let saveSuccess = this._saveCookies();
        if(saveSuccess){
            this._close();
            this.isAccepted = true;

            this.events.COOKIES_ACEPTADAS.dispatch();
        }
    }

    _saveCookies(){
        return lscache.set(cookieName,'yes',525600); //525600 1 año en minutos
    }

    _addListeners(){
        //button ok
        this.$btnOk.addEventListener('click',this.bindedMethods.onClickButtonOk,false);
    }
}

export default Cookies;
