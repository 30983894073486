import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ApolloClient from 'apollo-boost'
import VueApollo from 'vue-apollo'
import Cookies from '@/utils/cookies';
import Tracking from '@/utils/Tracking';

// import Swiper styles
import 'swiper/css';


import './scss/main.scss';

Vue.config.productionTip = false

Vue.use(VueApollo)


let _tracking =  new Tracking();

const apolloClient = new ApolloClient({
  uri: 'https://graph.volvocars.com/graphql',
  name: 'stockvolvo',
  version: '1.0',
  onError: () =>{

    // console.log("***** GLOBAL ERROR *****")
    changesToAlternativeServer();
  }
})

const apolloClientAlternative = new ApolloClient({
  uri: ' https://www.stockvolvocars.es/back-office/filters.php',
  name: 'stockvolvo',
  version: '1.0'
})


let _cookies =  new Cookies({
  $el: document.getElementById('cookies'),
  $btnOk: document.getElementById('btn__cookies--ok')
});

if(_cookies.checkCookiePrivacy()) {
  _tracking.load();
} else {
  _cookies.events.COOKIES_ACEPTADAS.addOnce(()=>{
    _tracking.load()
  });
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});



const apolloProvider = new VueApollo({
  clients: {
    apolloClient,
    apolloClientAlternative
  },
  defaultClient: apolloClient,
  // errorHandler (error) {
  //   console.log('Global error handler')
  //   console.error(error)
  // }
});


new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')



const changesToAlternativeServer = () => {
  document.body.classList.add('fallb-on');
  apolloProvider.defaultClient = apolloClientAlternative;
}
