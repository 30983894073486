<template src="./GlobalHeader.html"></template>
<style src="./GlobalHeader.scss" lang="scss"></style>
<script>
// import gsap from "gsap";

export default {
    methods: {
        toggleMobileExpanded: function() {

            // console.log()

            // console.log("toggleMobileExpanded")


            
            this.$store.dispatch('enableAnimacionVertical', false);
            
            if (!this.$store.getters.isFiltersMobileExpanded){
                this.$store.dispatch("setLastScrollY", Math.floor(window.scrollY))
                document.body.classList.add("mobile-filters-view");
            } else {
                document.body.classList.remove("mobile-filters-view");
                window.scrollY = this.$store.getters.lastWindowScrollY;
            }

            this.$store.dispatch('setFiltersMobileExpanded', !this.$store.getters.isFiltersMobileExpanded );

            
        },
    }
};
</script>
