<template src="./CarsGridItem.html"></template>
<style scoped src="./CarsGridItem.scss" lang="scss"></style>
<script>
  // import Swiper from 'swiper';
  import Swiper from 'swiper';
  import { util } from '@/utils/utils';
  import Tracking from '@/utils/Tracking';
  let _tracking = new Tracking();

  export default {
    name: "CarsGridItem",
    props: {
      car: Object,
    },
    filters: {
      precio: function(value) {
        return value.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      carga: function(value) {
        return value == 'Eléctrico/Gasolina' ? 'Híbrido Enchufable' : value;
      },
    },
    data: function() {
      return {
        actualSlide: 1,
        totalGallerySlides: 0
      };
    },
    computed: {
      getCarUrl() {

        let modelKey = util.getModelKey(this.car);
        let dealerCode = this.getDealerCode("dealer");
        const dealerCampaign = "IBLocalRetailerStockVNVCES"

        if (this.$store.getters.isProdEnv) {
          if (this.$store.getters.userHasConfiguratorInfo) {
            if(dealerCode && dealerCode != '') return `https://www.volvocars.com/es/quote?campaigncode=${dealerCampaign}&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.car.vehicle.model.code}/${this.car.vehicle.order.fyon}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}&retailerid=${dealerCode}`;
            else return `https://www.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.car.vehicle.model.code}/${this.car.vehicle.order.fyon}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}`;
          }
          else {
            if(dealerCode && dealerCode != '') return `https://www.volvocars.com/es/quote?campaigncode=${dealerCampaign}&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.car.vehicle.model.code}/${this.car.vehicle.order.fyon}&retailerid=${dealerCode}`;
            else return `https://www.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.car.vehicle.model.code}/${this.car.vehicle.order.fyon}`;
          }
        }
        else {
          if (this.$store.getters.userHasConfiguratorInfo) {
            if(dealerCode && dealerCode != '') return `https://qaoxp.volvocars.com/es/quote?campaigncode=${dealerCampaign}&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.car.vehicle.model.code}/${this.car.vehicle.order.fyon}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}&retailerid=${dealerCode}`;
            else return `https://qaoxp.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.car.vehicle.model.code}/${this.car.vehicle.order.fyon}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}`;
          }
          else {
            if(dealerCode && dealerCode != '') return `https://qaoxp.volvocars.com/es/quote?campaigncode=${dealerCampaign}&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.car.vehicle.model.code}/${this.car.vehicle.order.fyon}&retailerid=${dealerCode}`;
            else return `https://qaoxp.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.car.vehicle.model.code}/${this.car.vehicle.order.fyon}`;
          }
        }
      }
    },
    components: {},
    methods: {
      initGallery() {

        this.swiper = new Swiper(this.$refs.swipercontainer, {
          slidesPerView: 1,
          loop: true,
          on: {
            slideChange: (theSwiper) => {
              this.actualSlide = theSwiper.realIndex;
            },
            init: (theSwiper) => {
              this.actualSlide = theSwiper.realIndex;
              this.totalGallerySlides = theSwiper.slides.length - 2;
            }
          }
        });
      },
      tagContact() {
        _tracking.tag("HOME_CLICK_CONTACTAR_" + this.car.vehicle.model.description[0].text + "_" + this.car.vehicle.id);
      },
      slidePrev() {
        if (this.swiper)
          this.swiper.slidePrev();
      },
      slideNext() {
        if (this.swiper)
          this.swiper.slideNext();
      },
      getDealerCode(name) {
        let code = "";

        document.cookie.split('; ').map((cookie) => {
          let key = cookie.split("=")[0]
          let value = cookie.split("=")[1]

          if (name == key) code = value
        })

        return code
      }
    },
    mounted: function() {
      this.initGallery();
    },
    beforeDestroy() {
      if (this.swiper)
        this.swiper.destroy();
    }
  };
</script>
