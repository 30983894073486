const state = {
  colorsData: false, //data inicio
  color: [], // seleccionados
  filteredColorsAgg: null,
  colorGroups: [
    {
      name: "Azul",
      values: ["72300", "73400", "62600"],
      count: 0,
    },
    {
      name: "Blanco",
      values: ["61400", "70700"],
      count: 0,
    },
    {
      name: "Gris",
      values: [
        "73500",
        "73100",
        "72800",
        "73600",
        "71100",
        "72900",
        "72700",
        "74000",
      ],
      count: 0,
    },
    {
      name: "Negro",
      values: ["71700", "49200", "01900"],
      count: 0,
    },
    {
      name: "Rojo",
      values: ["72500"],
      count: 0,
    },
    {
      name: "Verde",
      values: ["72400", "73300"],
      count: 0,
    },
  ], // grupos
};

const mutations = {
  TOGGLE_COLOR(state, payload) {
    if (!state.color.includes(payload[0])) {
      state.color = state.color.concat(payload);
    } else {
      state.color = state.color.filter((item) => {
        let keep = true;
        for (let i = 0; i < payload.length; i++) {
          if (payload[i] === item) {
            keep = false;
          }
        }
        return keep;
      });
    }
  },
  REMOVE_ALL_COLOR(state) {
    state.color = [];
  },
  SETUP_INITIAL_COLORAGG(state, payload) {
    state.colorsData = payload;
  },
  SETUP_COLOR_GROUPS(state) {
    // state.colorGroups = [];
    state.colorsData.values.forEach((item) => {
      item.aggregation.values.forEach((theColor) => {
        //selecciona groupcolor
        let selColorGroup = state.colorGroups.find((o) => {
          return o.values.includes(theColor.value);
        });

        //actualiza contador
        if (selColorGroup) selColorGroup.count += theColor.count;
        // console.log(theColor.value, theColor.count, selColorGroup.name)
      });
    });
  },
  // UPDATE_COLOR_GROUPS(state, payload){
  //   state.filteredColorAgg = payload;
  // },
  UPDATE_FILTER_COLORAGG(state, payload) {
    state.filteredColorAgg = payload;

    state.colorGroups.forEach((cGroup) => {
      cGroup.count = 0;
    });

    state.filteredColorAgg.values.forEach((item) => {
      item.aggregation.values.forEach((theColor) => {
        //selecciona groupcolor
        let selColorGroup = state.colorGroups.find((o) => {
          return o.values.includes(theColor.value);
        });

        //actualiza contador
        if (selColorGroup) selColorGroup.count += theColor.count;
        //selColorGroup.count += theColor.count;
        // console.log(theColor.value, theColor.count, selColorGroup.name)
      });
    });
  },
};

const actions = {
  toggleColor: ({ commit }, payload) => {
    commit("TOGGLE_COLOR", payload);
  },
  deleteAllColor: ({ commit }, payload) => {
    commit("REMOVE_ALL_COLOR", payload);
  },
  setupColorGroups: ({ commit }, payload) => {
    commit("SETUP_COLOR_GROUPS", payload);
  },
  setupInitialColorAgg: ({ commit }, payload) => {
    commit("SETUP_INITIAL_COLORAGG", payload);
  },
  updateFilteredColorAgg: ({ commit }, payload) => {
    commit("UPDATE_FILTER_COLORAGG", payload);
  },
};

const getters = {
  color: (state) => {
    return state.color;
  },
  colorGroups: (state) => {
    return state.colorGroups;
  },
  getFilterColorConfig: (state) => {
    let o = [];
    for (let i = 0; i < state.color.length; i++) {
      o.push({
        value: state.color[i],
      });
    }
    return o;
  },
  getFilteredColorAgg: (state) => {
    return state.filteredColorsAgg;
  },
  //,
  // getColorGroupByColorId : state => idColor => {
  //   console.log("getColorGroupByColorId " + idColor);
  //   console.log(state, idColor);
  //   let sel = null;

  //   // state.color.values.forEach((colorGroup)=>{
  //   //   if ( !sel ){
  //   //     colorGroup.aggregation.values.forEach((  )=>{
  //   //       sel = colorGroups.find( ( o ) => {
  //   //         return o.values.includes(idColor);
  //   //       });
  //   //     });
  //   //   }
  //   // });
  //   return sel;
  // }
};

export default {
  state,
  mutations,
  actions,
  getters,
};
