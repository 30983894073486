<template src="./CarsGridFilter.html"></template>
<style scoped src="./CarsGridFilter.scss" lang="scss"></style>
<script>

import store from '@/store';
import * as noUiSlider from 'nouislider';
import Hammer from 'hammerjs';
// import gsap from "gsap";
import Tracking from '@/utils/Tracking';


let _tracking =  new Tracking();

const setupFilterConfig = (globalCfg) => {
  return globalCfg;
}

export default {
  name: "CarsGridFilter",
  props: {},
  components: {  
  },
  data(){
    return {
      // isReady: false,
      filterConfig: {},
      minRangeVisible: 0,
      maxRangeVisible: 0
    }
  },
  computed: {
    isFiltered() { 
      if(this.$store.getters.modelo.length || this.$store.getters.traccion.length || this.$store.getters.combustible.length) {
        return true;
      } else {
        return false;
      }
    }
  },
  filters: {
    precioRange: function (value) {
      let s = value.toString();
      let pos = s.indexOf(".");
      return pos > 0 ? s.slice(0, pos).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : s.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    carga: function (value) {
      return value == 'Eléctrico/Gasolina' ? 'Híbrido Enchufable' : value;
    }
  },
  watch: {
    '$store.state.initialConfigDone': function() {
      this.getGeneralFilterConfig();

      setTimeout(()=>{
        this.initRange();
      },150);

      
      // this.isReady = true; 
    }
  },
  emits : ["filtersUpdated"],
  methods: { 
    showBasicFilters: function(){
      setTimeout(()=>{
        // console.log("showBasicFilters");
        this.$refs.filterCombustible.classList.remove("-closed");
        this.$refs.filterModelo.classList.remove("-closed");
      },500)
    },
    toggleCollapse: function( e ){
      e.target.parentNode.classList.toggle("-closed");
    },
    initRange: function(){

      let range = document.getElementById('pricerange');

      this.rangeSlider = noUiSlider.create(range, {

          range: {
              'min': this.$store.getters.minPrecio,
              'max': this.$store.getters.maxPrecio
          },

          step: 1000,

          // Handles start at ...
          start: [this.$store.getters.minPrecio, this.$store.getters.maxPrecio],

          // ... must be at least 300 apart
          margin: 10000,

          // ... but no more than 600
          // limit: 600,

          // Display colored bars between handles
          connect: true,

          // Put '0' at the bottom of the slider
          // direction: 'rtl',
          // orientation: 'vertical',

          // Move handle on tap, bars are draggable
          behaviour: 'tap-drag',
          tooltips: false,
          // format: wNumb({
          //     decimals: 0
          // }),

          // Show a scale with the slider
          // pips: {
          //     mode: 'steps',
          //     stepped: true,
          //     density: 4
          // }
          });

          this.rangeSlider.on("update", () => {
            this.minRangeVisible = this.rangeSlider.get()[0];
            this.maxRangeVisible = this.rangeSlider.get()[1];
          });

          this.rangeSlider.on("change", () => {
            this.$store.dispatch('setFilteredPrices', {
              min: this.rangeSlider.get()[0],
              max: this.rangeSlider.get()[1]
            });
          });

    },

    resetPriceRange: function(){
      this.rangeSlider.set([this.$store.getters.minPrecio, this.$store.getters.maxPrecio]);
    },

    getGeneralFilterConfig: function() {
      this.filterConfig.traccion = setupFilterConfig(this.$store.getters.getInitialAggregationByName("driveType.code"));
      this.filterConfig.modelo = setupFilterConfig(this.$store.getters.getInitialAggregationByName("model.code"));
      this.filterConfig.combustible = setupFilterConfig(this.$store.getters.getInitialAggregationByName("engine.groupCode"));
      this.filterConfig.color = this.$store.getters.colorGroups;
      
      // this.filterConfig.priceInicialMin = this.$store.getters.colorGroups;
    },
    updateFilterConfig: function() {
      
      //updates traccion filter
      this.filterConfig.traccion.values.forEach((theOption)=>{
        let updatedOption = this.$store.getters.getFilteredTraccionAgg.values.find(el => el.value === theOption.value);
        if ( updatedOption ){
          theOption.count = updatedOption.count;
        } else {
          theOption.count = 0;
        }
      });


      //updates combustible filter
      this.filterConfig.combustible.values.forEach((theOption)=>{
        let updatedOption = this.$store.getters.getFilteredCombustibleAgg.values.find(el => el.value === theOption.value);
        if ( updatedOption ){
          theOption.count = updatedOption.count;
        } else {
          theOption.count = 0;
        }
      });


      //updates modelo filter
      this.filterConfig.modelo.values.forEach((theOption)=>{
        let updatedOption = this.$store.getters.getFilteredModeloAgg.values.find(el => el.value === theOption.value);
        if ( updatedOption ){
          theOption.count = updatedOption.count;
        } else {
          theOption.count = 0;
        }
      });

    },

    disableModesOnUserAction: function(){
      this.$store.dispatch('setFilterByToken', false);
      this.$store.dispatch('setFilterByModelkey', false);
      this.$store.dispatch('setModelkeyWarningNoStock', false);
      this.$store.dispatch('setTokenWarningNoStock', false);
    },
    
    //update filter state
    toggleFilterTraccion: function(filtro, name) {
      this.disableModesOnUserAction();
      
      if (this.$store.getters.traccion.includes(filtro)) {
        _tracking.tag("DISABLE_FILTRO_TRACCION_"+ name);
      } else {
        _tracking.tag("ENABLE_FILTRO_TRACCION_"+ name);
      }

      store.dispatch('resetPaginationIndex').then(() => {
        store.dispatch('toggleTraccion', filtro);
        this.$emit("filtersUpdated");
      }, () => {});
    },
    toggleFilterModelo: function(filtro, name) {
      this.disableModesOnUserAction();
      
      if (this.$store.getters.modelo.includes(filtro)) {
        _tracking.tag("DISABLE_FILTRO_MODELO_"+ name);
      } else {
        _tracking.tag("ENABLE_FILTRO_MODELO_"+ name);
      }
      
      store.dispatch('resetPaginationIndex').then(() => {
        store.dispatch('toggleModelo', filtro);
        this.$emit("filtersUpdated");
      }, () => {});
    },
    toggleFilterCombustible: function(filtro,name) {
      this.disableModesOnUserAction();
      if (this.$store.getters.combustible.includes(filtro)) {
        _tracking.tag("DISABLE_FILTRO_COMBUSTIBLE_"+ name);
      } else {
        _tracking.tag("ENABLE_FILTRO_COMBUSTIBLE_"+ name);
      }

      store.dispatch('resetPaginationIndex').then(() => {
        store.dispatch('toggleCombustible', filtro);
        this.$emit("filtersUpdated");
      }, () => {});
    },
    toggleFilterColor: function(filtro, name) {
      this.disableModesOnUserAction();
      

      if (this.$store.getters.color.includes(filtro)) {
        _tracking.tag("DISABLE_FILTRO_COLOR_"+ name);
      } else {
        _tracking.tag("ENABLE_FILTRO_COLOR_"+ name);
      }

      store.dispatch('resetPaginationIndex').then(() => {
        store.dispatch('toggleColor', filtro);
        this.$emit("filtersUpdated");
      });
    },

    //class checkers
    isTraccionEnabled: function(idTraccion){
      return this.$store.getters.traccion.includes(idTraccion);
    },
    isModeloEnabled: function(idModelo){
      return this.$store.getters.modelo.includes(idModelo);
    },
    isCombustibleEnabled: function(idCombustible){
      return this.$store.getters.combustible.includes(idCombustible);
    },
    isColorEnabled: function(idColor){
      return this.$store.getters.color.includes(idColor[0])
    },


    clearFilter: function(filterCat, isUserAction = true) {
      if(filterCat === "traccion") {
        if(isUserAction){ 
          _tracking.tag("BORRAR_FILTROS_CATEGORIA_TRACCION");
        }
        this.$store.dispatch('deleteAllTraccion');
      } else if(filterCat === "modelo") {
        if(isUserAction){ 
          _tracking.tag("BORRAR_FILTROS_CATEGORIA_MODELO");
        }
        this.$store.dispatch('deleteAllModelo');
      } else if(filterCat === "combustible") {
        if(isUserAction){ 
          _tracking.tag("BORRAR_FILTROS_CATEGORIA_COMBUSTIBLE");
        }
        this.$store.dispatch('deleteAllCombustible');
      } else if(filterCat === "color") {
        if(isUserAction){ 
          this.$store.dispatch('deleteAllColor');
        }
        _tracking.tag("BORRAR_FILTROS_CATEGORIA_COLOR");
      } else if(filterCat === "precio") {
        if(isUserAction){ 
          this.$store.dispatch('deleteAllPrecio');
        }
        this.resetPriceRange();
      }
    },

    clearAllFilters: function () {

      _tracking.tag("BORRAR_FILTROS");

      Promise.all([
        this.$store.dispatch('deleteAllTraccion'),
        this.$store.dispatch('deleteAllModelo'),
        this.$store.dispatch('deleteAllCombustible'),
        this.$store.dispatch('deleteAllPrecio'),
        this.$store.dispatch('deleteAllColor')
      ]).then(()=>{
        this.disableModesOnUserAction();
        this.resetPriceRange();
      });
    },

    toggleMobileExpanded: function() {    
        if (!this.$store.getters.isFiltersMobileExpanded){
            this.$store.dispatch("setLastScrollY", Math.floor(window.scrollY))
            document.body.classList.add("mobile-filters-view");
        } else {
            document.body.classList.remove("mobile-filters-view");
            window.scrollY = this.$store.getters.lastWindowScrollY;
        }
        this.$store.dispatch('setFiltersMobileExpanded', !this.$store.getters.isFiltersMobileExpanded );
    }
  },
  apollo: {},
  created(){
    //if lives again, get initial config
    if ( this.$store.getters.initialConfigDone ){
      this.getGeneralFilterConfig();

      // setTimeout(()=>{
      //   this.initRange();
      // },150);
    }
  },
  beforeDestroy() {
    if(this.rangeSlider)
      this.rangeSlider.destroy();
  },
  mounted(){
    //si ya existe y se vuelve a montar por navegación crea range component directamente
    if(this.$store.state.initialConfigDone){
      this.initRange();  
    }


    var square = document.querySelector('.filters');

      // Create an instance of Hammer with the reference.
      var hammer = new Hammer(square);

      hammer.get('swipe').set({ direction: Hammer.DIRECTION_ALL });

      // Subscribe to a quick start event: press, tap, or doubletap.
      // For a full list of quick start events, read the documentation.
      hammer.on('swipeup', () => {
        this.$store.dispatch('enableAnimacionVertical', true);
        this.toggleMobileExpanded();

        setTimeout(()=>{

          this.$store.dispatch('enableAnimacionVertical', false);
        }, 700)

      });

  }
};
</script>
