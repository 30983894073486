<template>
  <footer class="footer">
        <div class="content-wrapper">
            <ul class="links">
                <li><a href="https://www.volvocars.com/es/v/legal/cookies" target="_blank" >Cookies</a></li>
                <li><a href="https://www.volvocars.com/es/v/legal/legal" target="_blank" >Legal</a></li>
                <li><a href="https://www.volvocars.com/es/legal/privacy/privacy-customer-privacy-policy" target="_blank" >Política de Privacidad y Nota informativa</a></li>
                <li><a href="https://www.volvocars.com/es/l/legal/copyright/" target="_blank" >Copyright</a></li>
                <li><a href="https://www.volvocars.com/es/legal/privacy/privacy-terms-business-information" target="_blank" >Información de la empresa</a></li>
            </ul>
            <p class="legal mb-0">
                Copyright&copy; Volvo Car Corporation (o sus filiales o empresas licenciadoras) - Centro Atención al Cliente 91 566 62 37 
            </p>
        </div>
    </footer>
</template>

<script>

export default {
  name: 'MainFooter',
  props: {
  }
}
</script>

<style scoped lang="scss">

  @import "@/scss/_vars.scss";
  @import "@/scss/_responsive.scss";

    
  .footer {
    background: #fafafa; 
    padding: 30px 0;
    max-width: 1440px;
    margin: 0 auto;
    
    @include min-width("md") {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 35px 0 30px;
          font-size: 13px;
      }
      .content-wrapper {
          margin: 0 auto;
          width: 90%;
      }
    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        color: black;
        margin: 0;
        justify-content: center;
        margin: 0 0 10px;
        @include min-width("md") {
              margin: 0 0 0 0;
          }
        a {
              color: black;
        }

        li {
            list-style-type: none;
            margin: 0 8px 5px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
              margin-right: 0;
            }
        }
    }

    .legal {
          text-align: center;
          color: $primaryGray8C;
    }
  }

</style>
