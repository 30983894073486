const state = {
  traccion: [],
  filteredTraccionAgg: []
};

const mutations = {
  TOGGLE_TRACCION(state, payload) {
    if (!state.traccion.includes(payload)){
      state.traccion.push(payload);
    } else {
      state.traccion = state.traccion.filter(item => item !== payload)
    }
  },
  REMOVE_ALL_TRACCION(state) {
    state.traccion = [];
  },
  UPDATE_FILTER_TRACCIONAGG(state, payload){
    state.filteredTraccionAgg = payload;
  }
};

const actions = {
  toggleTraccion: ({ commit }, payload) => {
    commit("TOGGLE_TRACCION", payload);
  },
  deleteAllTraccion : ({ commit }, payload) => {
    commit("REMOVE_ALL_TRACCION", payload);
  },
  updateFilteredTraccionAgg: ({ commit }, payload) => {
    commit("UPDATE_FILTER_TRACCIONAGG", payload);
  }
};

const getters = {
  traccion: state => {
    return state.traccion;
  },
  getFilterTraccionConfig: ( state ) => {
    let o = []; 
    for (let i = 0; i < state.traccion.length; i++){
      o.push({
        "value": state.traccion[i]
      });
    }
    return o;
  },
  getFilteredTraccionAgg: ( state ) => {
    return state.filteredTraccionAgg;
  },
  // getFilteredTraccionAggById: ( state, idOption ) => {
  //   // return state.filteredTraccionAgg.filter();

  //   console.log("getFilteredTraccionAggById")
  //   // console.log( state.filteredTraccionAgg )

  //   return state.filteredTraccionAgg.values.find(el => el.value === idOption);
  // },
};

export default {
  state,
  mutations,
  actions,
  getters
};
  