<template src="./CarsGrid.html"></template>
<style scoped src="./CarsGrid.scss" lang="scss"></style>
<script>
  import CarsGridItem from "../CarsGridItem";
  // import gql from "graphql-tag"
  import GET_CARS from '@/queries/inicial.gql';
  import GET_CAR_BY_TOKEN from '@/queries/carbytoken.gql';
  import GET_TOKEN_CARS from '@/queries/tokencars.gql';
  import { util } from '@/utils/utils';


  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const getAggByName = (oAgg, idAgg) => {
    return oAgg.find(el => el.name === idAgg);
  }
  const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  export default {
    name: "CarsGrid",
    props: {},
    computed: {
      // ...mapGetters({
      //   pageSize: elementosVisible
      // })
    },
    components: {
      "app-carsgriditem": CarsGridItem
    },
    data() {
      return {
        totalCars: 0,
        pageSize: this.$store.getters.elementosVisible,
        page: this.$store.getters.paginationIndex,
        deliveryDate: addDays(new Date(), 40),
        hasMore: false,
        isLoadingMore: false,

        initialConfigFinished: false,
        isReady: false,
        filteredToken: "15844484758834482857",
        stockCars: {},
        isLoading: false,
        //token mode
        tokenCarConfig: {
          // modelo: String,
          // traccion: String,
          // combustible: String,
          // color: String
          //colorGroup: Array
        },
        tokenCars: {
          stockCars: {
            hits: []
          },
          ids: []
        },
        //URL params
        dealer: "",
      }
    },
    watch: {
      '$store.state.elementosVisible': function(newValue) {
        this.pageSize = newValue;
      },
      '$store.state.filterByTokenEnabled': function(newValue) {
        this.filterByTokenEnabled = newValue;
      },
      '$store.state.useAlternativeServer': function() {
        // console.log("useAlternativeServer CHANGES " + newValue);
        // useAltServer = newValue;
      },
    },
    mounted() {
      //If dealer param exists, we save it in a session cookie
      let param = this.$route.query.dealer
      if (param && param !== "") document.cookie = "dealer=" + param + "; expires=null; path=/;";
    },
    emits: ["gridUpdated", "isLoadingChanged", "modelkeyNoResults", "tokenNoResults", "tokenModeOnLoad", "modelkeyModeOnLoad"],
    methods: {
      initConfig(cfg) {

        this.$store.dispatch('configInitialAgreggations', cfg.stockCars.aggregations).then(() => {
          Promise.all([
            this.$store.dispatch('setupInitialColorAgg', this.$store.getters.getInitialAggregationByName("color.groupCode")),
            this.$store.dispatch('setupColorGroups'),
            this.$store.dispatch('updateFilteredCombustibleAgg', getAggByName(cfg.combustible.aggregations, "engine.groupCode")),
            this.$store.dispatch('updateFilteredModeloAgg', getAggByName(cfg.modelo.aggregations, "model.code")),
            this.$store.dispatch('updateFilteredTraccionAgg', getAggByName(cfg.traccion.aggregations, "driveType.code")),

            //TODO: evitar setear precio aquí, actualiza query
            this.$store.dispatch('setupInitialPrices', {
              minTotal: cfg.minimo.hits[0].vehicle.price.msrpAmount,
              maxTotal: cfg.maximo.hits[0].vehicle.price.msrpAmount,
            })

          ]).then(() => {


            // this.$store.dispatch('setInitialConfig', true);

            this.checkPreselectedState();
          })
        });
      },
      checkPreselectedState() {

        //preselect by token
        if (this.$route.query.token) {
          // console.log("preselect token")

          this.filteredToken = this.$route.query.token;
          this.preselectByToken();

          //preselect by URL PARAMS  
        }
        else if (this.$route.query.modelkey || this.$route.query.modelos || this.$route.query.traccion || this.$route.query.combustible || this.$route.query.color) {
          this.preselectByQuery();
        }
        else {

          // console.log("preselect NORMAL");
          //carga general sin nada preseleccionado
          this.$store.dispatch('setInitialConfig', true);
          // this.$apollo.queries.stockCars.skip = false;
        }

      },
      preselectByToken() {
        // console.log("preselectByToken");
        this.$apollo.queries.carByToken.skip = false;
      },
      onPreselectByToken(data) {
        // console.log("onPreselectByToken")
        // console.log(data)

        //configura valores para las urls que vienen del configurador
        this.$store.dispatch('setUserConfigurationInfo', {
          carConfiguratorUrl: this.$route.query.carConfiguratorUrl,
          cceurl: this.$route.query.cceurl,
          cceid: this.$route.query.token
        });

        //traccion
        this.tokenCarConfig.traccion = data.carByToken.driveline.content.driveType.value;

        //modelo
        this.tokenCarConfig.modelo = data.carByToken.carKey.carType;

        //color
        this.tokenCarConfig.color = data.carByToken.carKey.color;

        //familiy
        this.tokenCarConfig.family = data.carByToken.modelFamily.displayName.value

        // let colorGroupTokenCar = this.$store.getters.getColorGroupByColorID(data.carByToken.carKey.color);

        //get colorGroup of token car
        let colorIdTokenCar = data.carByToken.carKey.color;


        let groupFound = false;
        this.$store.getters.colorGroups.forEach(function(element) {
          if (element.values.includes(colorIdTokenCar)) {
            groupFound = element;
          }
        });

        // 
        if (groupFound) {
          // console.log("grupo encontrado") 
          this.tokenCarConfig.colorGroup = [];
          for (let i = 0; i < groupFound.values.length; i++) {
            this.tokenCarConfig.colorGroup.push({
              "value": groupFound.values[i]
            });
          }
        }


        //combustible
        this.tokenCarConfig.combustible = data.carByToken.engine.content.fuelType.value.toLowerCase();


        if (this.tokenCarConfig.combustible == "petrol_electric") {
          this.tokenCarConfig.combustible = "electric-petrol";
        }


        //PURE_ELECTRIC
        if (this.tokenCarConfig.combustible.toLowerCase() == "pure_electric") {
          this.tokenCarConfig.combustible = "electric";
        }

        console.log("FILTRO POR TOKEN");

        this.$store.dispatch('setFilterByToken', true).then(() => {

          this.$apollo.queries.tokenCars.skip = false;

          //updates "otros resultados"
          this.$store.dispatch('toggleModelo', this.tokenCarConfig.modelo);

          this.$store.dispatch('toggleCombustible', this.tokenCarConfig.combustible);
          this.$emit("tokenModeOnLoad");

        });

      },
      preselectByQuery() {


        //hide temporary till filtered query is done
        this.$store.dispatch('setFilterByQuery', true);

        if (this.$route.query.modelkey) {
          this.$store.dispatch('setFilterByModelkey', true);
          this.$store.dispatch('setFilterByModelkeyModel', util.readModelKey(this.$route.query.modelkey).textDisplay[0]);
        }

        //inicio en base a url queried parameters
        let selectedModelos = [];
        let selectedTraccion = [];
        let selectedCombustible = [];
        let selectedColor = [];

        //gestiona si viene algo preseleccionado, sino se da por ok la configuración inicial      
        if (this.$route.query.modelos) {
          const modelos = this.$store.getters.getInitialAggregationByName("model.code");
          let queryModelos = [];

          queryModelos = this.$route.query.modelos.toUpperCase().split(",");

          queryModelos.forEach(function(selM) {
            const found = modelos.values.find((element) => {
              let toSearch = selM.toLowerCase().replaceAll("-", " ");
              return element.description[0].text.toLowerCase() == toSearch;
            });
            if (found) {
              selectedModelos.push(found);
            }
          });


          //si viene un query por modelos pero no existe
          //si es uno valido y es de una familia recomiendo familias

          if (selectedModelos.length == 0) {
            let familiesGroups = util.getFamiliesRelations();
            let recomendedFamily = [];
            familiesGroups.forEach((family) => {
              if (family.includes(queryModelos[0])) {
                recomendedFamily = family;
              }
            });

            recomendedFamily
            let recomendedModelsIds = [];
            const allModelos = this.$store.getters.getInitialAggregationByName("model.code");
            allModelos.values.forEach((familyModel) => {
              if (recomendedFamily.includes(familyModel.description[0].text)) {
                recomendedModelsIds.push(familyModel.value);
              }
            });

            //hay familias que buscar / recomendar
            if (recomendedModelsIds.length) {
              let aFiltersModelos = [];
              recomendedModelsIds.forEach((modeloID) => {
                aFiltersModelos.push(this.$store.dispatch('toggleModelo', modeloID));
              });

              //quita el filtro de combustible puesto antes por token
              aFiltersModelos.push(this.$store.dispatch('deleteAllCombustible'));

              Promise.all(aFiltersModelos).then(() => {
                // console.log("all filtros familias seleccionados");
                this.$store.dispatch('setTokenWarningNoStock', true);
              });
            }
            else {
              this.$store.dispatch('setFilterByQuery', false).then(() => {
                this.$store.dispatch('setInitialConfig', true);
              });
              return false;

            }
          }
          //FIN RECOMENDACION POR FAMILIA ON PAGE LOAD CON MODELOS NO ENCONTRADO

        }




        if (this.$route.query.traccion) {

          const tracciones = this.$store.getters.getInitialAggregationByName("driveType.code");

          let queryTraccion = [];
          queryTraccion = this.$route.query.traccion.toUpperCase().split(",");

          queryTraccion.forEach(function(selM) {
            const found = tracciones.values.find((element) => {
              return element.value.toLowerCase() == selM.toLowerCase();
            });
            if (found) {
              selectedTraccion.push(found);
            }
          });
        }


        if (this.$route.query.combustible) {

          const combustibles = this.$store.getters.getInitialAggregationByName("engine.groupCode");

          let queryCombustible = [];
          queryCombustible = this.$route.query.combustible.split(",");

          queryCombustible.forEach(function(selM) {
            const found = combustibles.values.find((element) => {
              // let toSearch = selM.toLowerCase().replaceAll("-", " ").replaceAll("_", "/");
              // console.log(element.value.toLowerCase(), toSearch)
              return element.value == selM;
            });
            if (found) {
              selectedCombustible.push(found);
            }
          });
        }



        if (this.$route.query.color) {

          const colores = this.$store.getters.colorGroups;

          let queryColor = [];
          queryColor = this.$route.query.color.split(",");

          queryColor.forEach(function(selM) {
            const found = colores.find((element) => {
              return element.name.toLowerCase() == selM.toLowerCase();
            });
            if (found) {
              selectedColor.push(found);
            }
          });
        }




        //comprueba si hay modelos por modelkey
        //SI LOS HAY SOBREESCRIBE LOS QUE PUEDA HABER POR URL
        //ES LA EXCEPCION DE LAS URLS DE LOS CONESIONARIOS
        if (this.$route.query.modelkey) {

          let modelKeyConfig = util.readModelKey(this.$route.query.modelkey);


          //combustible
          selectedCombustible = [];
          modelKeyConfig.combustible.forEach((idComb) => {
            const combustiblesRef = this.$store.getters.getInitialAggregationByName("engine.groupCode");
            const found = combustiblesRef.values.find((element) => {
              return element.value == idComb;
            });
            if (found) {
              selectedCombustible.push(found);
            }
          });


          //loop por modelos, llegará 1 
          selectedModelos = [];
          modelKeyConfig.model.forEach((idMod) => {
            if (idMod) {
              const modelosRef = this.$store.getters.getInitialAggregationByName("model.code");
              const found = modelosRef.values.find((element) => {
                if(! element?.description[0]?.text) return false
                
                let toSearch = idMod.toLowerCase().replaceAll("-", " ");
                return element.description[0].text.toLowerCase() == toSearch;
              });
              if (found) {
                selectedModelos.push(found);
              }
            }
          });

          if (selectedModelos.length == 0) {
            this.$store.dispatch('setModelkeyWarningNoStock', true);
          }
        }

        //si los filtros existen y son válidos
        if (selectedModelos.length || selectedTraccion.length || selectedCombustible.length || selectedColor.length) {

          let actionsToSelect = [];

          //MODELOS PRESELECCIONADOS POR URL

          //por modelo
          selectedModelos.forEach((m) => {
            actionsToSelect.push(this.$store.dispatch('toggleModelo', m.value));
          });

          //por traccion
          selectedTraccion.forEach((m) => {
            actionsToSelect.push(this.$store.dispatch('toggleTraccion', m.value));
          });

          //por combustible
          selectedCombustible.forEach((m) => {
            actionsToSelect.push(this.$store.dispatch('toggleCombustible', m.value));
          });

          selectedColor.forEach((m) => {
            actionsToSelect.push(this.$store.dispatch('toggleColor', m.values));
          });

          //aplica todos los query filtros iniciales y OK
          if (actionsToSelect.length) {
            Promise.all(actionsToSelect).then(() => {
              this.$store.dispatch('setInitialConfig', true).then(() => {
                if (this.$route.query.modelkey) {
                  this.$emit("modelkeyModeOnLoad");
                }
              });
            });

          }
        }
        else {
          this.$store.dispatch('setInitialConfig', true).then(() => {


            this.$emit("modelkeyModeOnLoad");

            //no results on modelkey --> show all
            if (this.$route.query.modelkey) {
              if (this.$store.getters.isFilteringByQuerie) {
                this.$store.dispatch('setFilterByQuery', false);
              }
            }
          });

        }


      },
      updateFilteredConfig(cfg) {
        //update filtered aggregations
        return Promise.all([
          this.$store.dispatch('updateFilteredCombustibleAgg', getAggByName(cfg.combustible.aggregations, "engine.groupCode")),
          this.$store.dispatch('updateFilteredModeloAgg', getAggByName(cfg.modelo.aggregations, "model.code")),
          this.$store.dispatch('updateFilteredTraccionAgg', getAggByName(cfg.traccion.aggregations, "driveType.code")),
          this.$store.dispatch('updateFilteredColorAgg', getAggByName(cfg.color.aggregations, "color.groupCode"))
        ])
      },
      updateURL() {

        let updatedQuery = {}


        let modelosFound = [];
        let combustiblesFound = [];
        let traccionesFound = [];
        let coloresFound = [];


        //update modelos
        if (this.$store.getters.modelo.length) {
          const modelos = this.$store.getters.getInitialAggregationByName("model.code");
          this.$store.getters.modelo.forEach((modeloActivo) => {
            let found = modelos.values.find((el) => {
              return el.value === modeloActivo;
            });
            if (found) {
              modelosFound.push(found.description[0].text.toLowerCase().replaceAll(" ", "-"));
            }
          });
          if (modelosFound.length) {
            updatedQuery.modelos = modelosFound.toString()
          }
        }


        //update combustible
        if (this.$store.getters.combustible.length) {
          const combustibles = this.$store.getters.getInitialAggregationByName("engine.groupCode");
          this.$store.getters.combustible.forEach((combustibleActivo) => {
            let found = combustibles.values.find((el) => {
              return el.value === combustibleActivo;
            });
            if (found) {
              combustiblesFound.push(found.value);
              // combustiblesFound.push(found.description[0].text.toLowerCase().replaceAll(" ", "-").replaceAll("/", "_"));
            }
          });
          if (combustiblesFound.length) {
            updatedQuery.combustible = combustiblesFound.toString()
          }
        }


        //update traccion
        if (this.$store.getters.traccion.length) {
          const tracciones = this.$store.getters.getInitialAggregationByName("driveType.code");
          this.$store.getters.traccion.forEach((traccionActivo) => {
            let found = tracciones.values.find((el) => {
              return el.value === traccionActivo;
            });
            if (found) {
              traccionesFound.push(found.value.toLowerCase().replaceAll(" ", "-"));
            }
          });
          if (traccionesFound.length) {
            updatedQuery.traccion = traccionesFound.toString()
          }
        }



        //update colores
        if (this.$store.getters.color.length) {
          const colores = this.$store.getters.colorGroups;

          this.$store.getters.color.forEach((colorActivo) => {

            let found = colores.find((elColorGroup) => {
              if (coloresFound.indexOf(elColorGroup.name.toLowerCase()) >= 0) {
                return false;
              }
              return elColorGroup.values.indexOf(colorActivo) >= 0 ? elColorGroup.name : false;
            });
            if (found) {
              coloresFound.push(found.name.toLowerCase());
            }
          });
          if (coloresFound.length) {
            updatedQuery.color = coloresFound.toString()
          }
        }


        if (!shallowEqual(this.$route.query, updatedQuery)) {
          this.$router.push({ query: updatedQuery })
        }

        // if (Object.keys(updatedQuery).length )
        // {
        //   if(shallowEqual(this.$route.query, updatedQuery)) {
        //      this.$router.push({ query: updatedQuery })
        //   }
        // }

      },
      async loadMore() {

        this.isLoadingMore = true;

        // this.page++;     

        let fireLoadMore = () => {

          this.$apollo.queries.stockCars.fetchMore({
            variables: {
              "filter": {
                "filter": {
                  "value": {
                    "marketCode": {
                      "value": [{
                        "value": "ES"
                      }]
                    },
                    "available": {
                      "value": [{
                        "value": true
                      }]
                    },
                    "orderDeliveryDate": {
                      "value": [{
                        "value": {
                          "max": this.deliveryDate
                        }
                      }]
                    },
                    "modelCode": {
                      "value": this.$store.getters.getFilterModeloConfig
                    },
                    "fuelType": {
                      "value": this.$store.getters.getFilterCombustibleConfig
                    },
                    "driveTypeCode": {
                      "value": this.$store.getters.getFilterTraccionConfig
                    },
                    "colorCode": {
                      "value": this.$store.getters.getFilterColorConfig
                    },
                    "msrpPrice": {
                      "value": [{
                        "value": {
                          "max": this.$store.getters.maxPrecioVisible,
                          "min": this.$store.getters.minPrecioVisible
                        }
                      }]
                    }
                  }
                },
                "take": this.pageSize,
                "skip": this.pageSize * this.$store.getters.paginationIndex,
                "sort": [{
                  "field": "msrpPrice",
                  "desc": false
                }]
              },
              "filtermax": {
                "filter": {
                  "value": {
                    "marketCode": {
                      "value": [{
                        "value": "ES"
                      }]
                    },
                    "available": {
                      "value": [{
                        "value": true
                      }]
                    },
                    "orderDeliveryDate": {
                      "value": [{
                        "value": {
                          "max": this.deliveryDate
                        }
                      }]
                    }
                  }
                },
                "take": 1,
                "sort": [{
                  "field": "msrpPrice",
                  "desc": true
                }]
              },
              "filtermin": {
                "filter": {
                  "value": {
                    "marketCode": {
                      "value": [{
                        "value": "ES"
                      }]
                    },
                    "available": {
                      "value": [{
                        "value": true
                      }]
                    },
                    "orderDeliveryDate": {
                      "value": [{
                        "value": {
                          "max": this.deliveryDate
                        }
                      }]
                    }
                  }
                },
                "take": 1,
                "sort": [{
                  "field": "msrpPrice",
                  "desc": false
                }]
              },
              "filtermodelo": {
                "filter": {
                  "value": {
                    "marketCode": {
                      "value": [{
                        "value": "ES"
                      }]
                    },
                    "available": {
                      "value": [{
                        "value": true
                      }]
                    },
                    "orderDeliveryDate": {
                      "value": [{
                        "value": {
                          "max": this.deliveryDate
                        }
                      }]
                    },
                    "fuelType": {
                      "value": this.$store.getters.getFilterCombustibleConfig
                    },
                    "driveTypeCode": {
                      "value": this.$store.getters.getFilterTraccionConfig
                    },
                    "colorCode": {
                      "value": this.$store.getters.getFilterColorConfig
                    },
                    "msrpPrice": {
                      "value": [{
                        "value": {
                          "max": this.$store.getters.maxPrecioVisible,
                          "min": this.$store.getters.minPrecioVisible
                        }
                      }]
                    }
                  }
                }
              },
              "filtercombustible": {
                "filter": {
                  "value": {
                    "marketCode": {
                      "value": [{
                        "value": "ES"
                      }]
                    },
                    "available": {
                      "value": [{
                        "value": true
                      }]
                    },
                    "orderDeliveryDate": {
                      "value": [{
                        "value": {
                          "max": this.deliveryDate
                        }
                      }]
                    },
                    "modelCode": {
                      "value": this.$store.getters.getFilterModeloConfig
                    },
                    "driveTypeCode": {
                      "value": this.$store.getters.getFilterTraccionConfig
                    },
                    "colorCode": {
                      "value": this.$store.getters.getFilterColorConfig
                    },
                    "msrpPrice": {
                      "value": [{
                        "value": {
                          "max": this.$store.getters.maxPrecioVisible,
                          "min": this.$store.getters.minPrecioVisible
                        }
                      }]
                    }
                  }
                }
              },
              "filtertraccion": {
                "filter": {
                  "value": {
                    "marketCode": {
                      "value": [{
                        "value": "ES"
                      }]
                    },
                    "available": {
                      "value": [{
                        "value": true
                      }]
                    },
                    "orderDeliveryDate": {
                      "value": [{
                        "value": {
                          "max": this.deliveryDate
                        }
                      }]
                    },
                    "modelCode": {
                      "value": this.$store.getters.getFilterModeloConfig
                    },
                    "fuelType": {
                      "value": this.$store.getters.getFilterCombustibleConfig
                    },
                    "colorCode": {
                      "value": this.$store.getters.getFilterColorConfig
                    },
                    "msrpPrice": {
                      "value": [{
                        "value": {
                          "max": this.$store.getters.maxPrecioVisible,
                          "min": this.$store.getters.minPrecioVisible
                        }
                      }]
                    }
                  }
                },
              },
              "filtercolor": {
                "filter": {
                  "value": {
                    "marketCode": {
                      "value": [{
                        "value": "ES"
                      }]
                    },
                    "available": {
                      "value": [{
                        "value": true
                      }]
                    },
                    "orderDeliveryDate": {
                      "value": [{
                        "value": {
                          "max": this.deliveryDate
                        }
                      }]
                    },
                    "modelCode": {
                      "value": this.$store.getters.getFilterModeloConfig
                    },
                    "fuelType": {
                      "value": this.$store.getters.getFilterCombustibleConfig
                    },
                    "driveTypeCode": {
                      "value": this.$store.getters.getFilterTraccionConfig
                    },
                    "msrpPrice": {
                      "value": [{
                        "value": {
                          "max": this.$store.getters.maxPrecioVisible,
                          "min": this.$store.getters.minPrecioVisible
                        }
                      }]
                    }
                  }
                }
              },
              "marketOrLocale": "ES",
              "locale": "es-ES"
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              fetchMoreResult.stockCars.hits = [...prev.stockCars.hits, ...fetchMoreResult.stockCars.hits];
              this.isLoadingMore = false;
              return fetchMoreResult;
            }
          });

        }


        this.$store.dispatch('nextPaginationIndex').then(fireLoadMore, () => {
          // console.log("ERROR");
        });
      }
    },
    apollo: {
      stockCars: {
        query: GET_CARS,
        // client: useAltServer ? 'apolloClientAlternative' : "apolloClient",
        // client: "apolloClientAlternative",
        watchLoading(isLoading) {
          // loading += countModifier
          this.$emit("isLoadingChanged", isLoading);
        },
        variables() {
          return {
            "filter": {
              "filter": {
                "value": {
                  "marketCode": {
                    "value": [{
                      "value": "ES"
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  },
                  "orderDeliveryDate": {
                    "value": [{
                      "value": {
                        "max": this.deliveryDate
                      }
                    }]
                  },
                  "modelCode": {
                    "value": this.$store.getters.getFilterModeloConfig
                  },
                  "fuelType": {
                    "value": this.$store.getters.getFilterCombustibleConfig
                  },
                  "driveTypeCode": {
                    "value": this.$store.getters.getFilterTraccionConfig
                  },
                  "colorCode": {
                    "value": this.$store.getters.getFilterColorConfig
                  },
                  "msrpPrice": {
                    "value": [{
                      "value": {
                        "max": this.$store.getters.maxPrecioVisible,
                        "min": this.$store.getters.minPrecioVisible
                      }
                    }]
                  }
                }
              },
              "take": this.pageSize,
              "skip": 0,
              "sort": [{
                "field": "msrpPrice",
                "desc": false
              }]
            },
            "filtermax": {
              "filter": {
                "value": {
                  "marketCode": {
                    "value": [{
                      "value": "ES"
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  },
                  "orderDeliveryDate": {
                    "value": [{
                      "value": {
                        "max": this.deliveryDate
                      }
                    }]
                  }
                }
              },
              "take": 1,
              "sort": [{
                "field": "msrpPrice",
                "desc": true
              }]
            },
            "filtermin": {
              "filter": {
                "value": {
                  "marketCode": {
                    "value": [{
                      "value": "ES"
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  },
                  "orderDeliveryDate": {
                    "value": [{
                      "value": {
                        "max": this.deliveryDate
                      }
                    }]
                  }
                }
              },
              "take": 1,
              "sort": [{
                "field": "msrpPrice",
                "desc": false
              }]
            },
            "filtermodelo": {
              "filter": {
                "value": {
                  "marketCode": {
                    "value": [{
                      "value": "ES"
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  },
                  "orderDeliveryDate": {
                    "value": [{
                      "value": {
                        "max": this.deliveryDate
                      }
                    }]
                  },
                  "fuelType": {
                    "value": this.$store.getters.getFilterCombustibleConfig
                  },
                  "driveTypeCode": {
                    "value": this.$store.getters.getFilterTraccionConfig
                  },
                  "colorCode": {
                    "value": this.$store.getters.getFilterColorConfig
                  },
                  "msrpPrice": {
                    "value": [{
                      "value": {
                        "max": this.$store.getters.maxPrecioVisible,
                        "min": this.$store.getters.minPrecioVisible
                      }
                    }]
                  }
                }
              }
            },
            "filtercombustible": {
              "filter": {
                "value": {
                  "marketCode": {
                    "value": [{
                      "value": "ES"
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  },
                  "orderDeliveryDate": {
                    "value": [{
                      "value": {
                        "max": this.deliveryDate
                      }
                    }]
                  },
                  "modelCode": {
                    "value": this.$store.getters.getFilterModeloConfig
                  },
                  "driveTypeCode": {
                    "value": this.$store.getters.getFilterTraccionConfig
                  },
                  "colorCode": {
                    "value": this.$store.getters.getFilterColorConfig
                  },
                  "msrpPrice": {
                    "value": [{
                      "value": {
                        "max": this.$store.getters.maxPrecioVisible,
                        "min": this.$store.getters.minPrecioVisible
                      }
                    }]
                  }
                }
              }
            },
            "filtertraccion": {
              "filter": {
                "value": {
                  "marketCode": {
                    "value": [{
                      "value": "ES"
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  },
                  "orderDeliveryDate": {
                    "value": [{
                      "value": {
                        "max": this.deliveryDate
                      }
                    }]
                  },
                  "modelCode": {
                    "value": this.$store.getters.getFilterModeloConfig
                  },
                  "fuelType": {
                    "value": this.$store.getters.getFilterCombustibleConfig
                  },
                  "colorCode": {
                    "value": this.$store.getters.getFilterColorConfig
                  },
                  "msrpPrice": {
                    "value": [{
                      "value": {
                        "max": this.$store.getters.maxPrecioVisible,
                        "min": this.$store.getters.minPrecioVisible
                      }
                    }]
                  }
                }
              },
            },
            "filtercolor": {
              "filter": {
                "value": {
                  "marketCode": {
                    "value": [{
                      "value": "ES"
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  },
                  "orderDeliveryDate": {
                    "value": [{
                      "value": {
                        "max": this.deliveryDate
                      }
                    }]
                  },
                  "modelCode": {
                    "value": this.$store.getters.getFilterModeloConfig
                  },
                  "fuelType": {
                    "value": this.$store.getters.getFilterCombustibleConfig
                  },
                  "driveTypeCode": {
                    "value": this.$store.getters.getFilterTraccionConfig
                  },
                  "msrpPrice": {
                    "value": [{
                      "value": {
                        "max": this.$store.getters.maxPrecioVisible,
                        "min": this.$store.getters.minPrecioVisible
                      }
                    }]
                  }
                }
              }
            },
            "marketOrLocale": "ES",
            "locale": "es-ES"
          }
        },
        error() {

          this.$store.dispatch('setAlternativeServer', true);
        },
        result(ApolloQueryResult) {
          // result ({ data }) {

          // console.log(JSON.parse(ApolloQueryResult));
          const data = ApolloQueryResult.data;
          
          if (!data) {
            // console.log("GQL SERVER FAILED");
            setTimeout(() => {
              this.$apollo.queries.stockCars.refresh();
            }, 2000);
            return;
          }

          //update has more state button
          let visibleItems = (this.pageSize * this.$store.getters.paginationIndex) + data.stockCars.metadata.returnedHits;
          this.hasMore = !(visibleItems >= data.stockCars.metadata.totalHits);

          //update value for total cars
          this.totalCars = data.stockCars.metadata.totalHits;

          //updates total results count
          this.$store.dispatch('setTotalHits', this.totalCars);

          //TODO: llevar a inicio?
          // console.log("check " + this.$store.getters.initialConfigDone);
          if (!this.$store.getters.initialConfigDone) {
            this.initConfig(data);
          }
          else {
            //siempre actualiza los valores de los filtros filtrados por la seleccion actual
            this.updateFilteredConfig(data).then(() => {
              this.$emit("gridUpdated");
            });
          }


          //si ha sido ocultado temporalmente por un primer filtrado de carga, lo quita
          // console.log("this.$store.getters.isFilteringByQuerie " + this.$store.getters.isFilteringByQuerie)
          if (this.$store.getters.isFilteringByQuerie) {
            this.$store.dispatch('setFilterByQuery', false);
          }


          if (this.$store.getters.isFilteringByModelkey) {
            // console.log("FILTERING BY MODELKEY ", this.totalCars);
            this.$store.dispatch('setModelkeyResults', this.totalCars);

            if (this.totalCars == 0) {
              // console.log("EMPTY MODELKEY");
              this.$store.dispatch('setModelkeyWarningNoStock', true);
              this.$emit("modelkeyNoResults");
            }

          }



          if (this.$store.getters.filterByTokenEnabled) {
            // console.log("FILTERING BY MODELKEY ", this.totalCars);
            if (this.totalCars == 0) {
              if (this.tokenCars.stockCars.hits.length == 0) {
                //NO HAY COCHES TOKEN, NI NINGUN COCHE DE ESE MODELO / COMBUSTIBLE
                let familiesGroups = util.getFamiliesRelations();
                //selecciona familia
                let recomendedFamily = [];
                familiesGroups.forEach((family) => {
                  if (family.includes(this.tokenCarConfig.family)) {
                    recomendedFamily = family;
                  }
                });

                let recomendedModelsIds = [];
                const allModelos = this.$store.getters.getInitialAggregationByName("model.code");
                allModelos.values.forEach((familyModel) => {
                  if (recomendedFamily.includes(familyModel.description[0].text)) {
                    recomendedModelsIds.push(familyModel.value);
                  }
                });

                //hay familias que buscar / recomendar
                if (recomendedModelsIds) {
                  let aFiltersModelos = [];
                  recomendedModelsIds.forEach((modeloID) => {
                    aFiltersModelos.push(this.$store.dispatch('toggleModelo', modeloID));
                  });

                  //quita el filtro de combustible puesto antes por token
                  aFiltersModelos.push(this.$store.dispatch('deleteAllCombustible'));

                  Promise.all(aFiltersModelos).then(() => {
                    console.log("all filtros familias seleccionados");
                  });
                }
                else {
                  //haciendo que sino encunetra ni token, ni primos, ni familias que recomendar desactiva entrada token
                  this.$store.dispatch('setFilterByToken', false);
                  this.$emit("tokenNoResults");
                }

              }
            }

          }


          //update url query
          // this.updateURL();
        }

      },
      carByToken: {
        query: GET_CAR_BY_TOKEN,
        // client: useAltServer ? 'apolloClientAlternative' : "apolloClient",
        // manual: true,
        // pollInterval: 0,
        skip: true,
        variables() {
          return {
            "token": this.filteredToken,
            "locale": "es-ES"
          }
        },
        result({ data }) {
          // console.log("CAR BY TOKEN RESULT")
          // console.log( data );

          if (data) {
            this.onPreselectByToken(data);
          }
          else {
            this.$apollo.queries.carByToken.skip = true;
            this.$store.dispatch('setFilterByToken', false).then(() => {
              this.$store.dispatch('setInitialConfig', true);
            });
          }

        }
      },
      tokenCars: {
        query: GET_TOKEN_CARS,
        // client: useAltServer ? 'apolloClientAlternative' : "apolloClient",
        skip: true,
        variables() {
          return {
            "filter": {
              "filter": {
                "value": {
                  "marketCode": {
                    "value": [{
                      "value": "ES"
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  },
                  "orderDeliveryDate": {
                    "value": [{
                      "value": {
                        "max": this.deliveryDate
                      }
                    }]
                  },
                  "modelCode": {
                    "value": [{
                      "value": this.tokenCarConfig.modelo
                    }]
                  },
                  "fuelType": {
                    "value": [{
                      "value": this.tokenCarConfig.combustible
                    }]
                  },
                  "driveTypeCode": {
                    "value": [{
                      "value": this.tokenCarConfig.traccion
                    }]
                  },
                  "colorCode": {
                    "value": this.tokenCarConfig.colorGroup
                  }
                }
              },
              "take": 36, // 12 por defecto
              "skip": 0 //take * pagina
            },
            "marketOrLocale": "ES",
            "locale": "es-ES"
          }
        },
        result({ data }) {

          this.tokenCars = data;

          this.tokenCars.ids = [];
          this.tokenCars.stockCars.hits.forEach((car) => {
            this.tokenCars.ids.push(car.vehicle.id)
          });


          if (this.tokenCars.stockCars.hits.length == 0) {
            this.$store.dispatch('setTokenWarningNoStock', true);
          }


          this.$store.dispatch('setCarsFilteredByToken', this.tokenCars.ids);

          //da por finalizada el inicio por token
          this.$store.dispatch('setInitialConfig', true);

        }
      }
    }
  };
</script>
