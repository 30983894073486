<template src="./CarsGridHeader.html"></template>
<style scoped src="./CarsGridHeader.scss" lang="scss"></style>
<script>
export default {
    methods: {
        toggleExpanded: function() {
            
            // setFiltersExpanded
            
            this.$store.dispatch('setFiltersExpanded', !this.$store.getters.isFiltersExpanded );
        }
    }
};
</script>
