<template src="./CarDetail.html"></template>
<style src="./CarDetail.scss" lang="scss"></style>
<script>
  import GET_CAR_DETAIL from '@/queries/cardetail.gql';
  import Swiper from 'swiper';
  import { util } from '@/utils/utils';
  import Tracking from '@/utils/Tracking';
  let _tracking = new Tracking();

  let initAccordion = function(el) {
    let btns = el.querySelectorAll(".accordion-btn");
    let accordionContent = el.querySelectorAll(".accordion-content-wrapper");
    accordionContent = [...accordionContent];

    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", () => {
        accordionContent[i].classList.toggle("active")
      });
    }
  }

  export default {
    name: "CarsDetail",
    props: {},
    filters: {
      precio: function(value) {
        return value.toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    computed: {
      getColorCodeCar() {
        return this.colorGroupCar.name;

      },
      getCarUrl() {

        let modelKey = util.getModelKey(this.theCar);
        let dealerCode = this.getDealerCode("dealer");
        const dealerCampaign = "IBLocalRetailerStockVNVCES"

        if (this.$store.getters.isProdEnv) {

          if (this.$store.getters.userHasConfiguratorInfo) {
            if(dealerCode && dealerCode != '') return `https://www.volvocars.com/es/quote?campaigncode=${dealerCampaign}&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.vehicleModelCode}/${this.fyon}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}&retailerid=${dealerCode}`;
            else return `https://www.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.vehicleModelCode}/${this.fyon}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}`;
          }
          else {
            if(dealerCode && dealerCode != '') return `https://www.volvocars.com/es/quote?campaigncode=${dealerCampaign}&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.vehicleModelCode}/${this.fyon}&retailerid=${dealerCode}`;
            else return `https://www.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.vehicleModelCode}/${this.fyon}`;
          }
        }
        else {
          if (this.$store.getters.userHasConfiguratorInfo) {
            if(dealerCode && dealerCode != '') return `https://qaoxp.volvocars.com/es/quote?campaigncode=${dealerCampaign}&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.vehicleModelCode}/${this.fyon}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}&retailerid=${dealerCode}`;
            else return `https://qaoxp.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.vehicleModelCode}/${this.fyon}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}`;
          }
          else {
            if(dealerCode && dealerCode != '') return `https://qaoxp.volvocars.com/es/quote?campaigncode=${dealerCampaign}&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.vehicleModelCode}/${this.fyon}&retailerid=${dealerCode}`;
            else return `https://qaoxp.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.vehicleModelCode}/${this.fyon}`;
          }

        }

        // if ( this.$store.getters.isProdEnv ){

        //   if ( this.$store.getters.userHasConfiguratorInfo ){
        //     return `https://www.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.carId}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}`;
        //   } else {
        //     return `https://www.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.carId}`;
        //   }

        // } else {

        //   if ( this.$store.getters.userHasConfiguratorInfo ){
        //     return `https://qaoxp.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.carId}&cceurl=${this.$store.getters.userConfiguratorInfo.carConfiguratorUrl}&cceid=${this.$store.getters.userConfiguratorInfo.cceid}`;
        //   } else {
        //     return `https://qaoxp.volvocars.com/es/quote?campaigncode=IBLocalInventoryVCES&modelkey=${modelKey}&additionalinfo=ID%20Vehiculo%20NCL%20${this.carId}`;
        //   }

        // }

      }
    },
    data: function() {
      return {
        actualSlide: 1,
        totalGallerySlides: 0,
        dataDetallesTecnicos: {
          enabled: false
        },
        dataDetallesOpciones: {
          enabled: false
        },
        dataDetallesPackages: {
          enabled: false
        },
        stockCars: {},
        colorGroupCar: {
          name: ""
        },
        carId: "",
        carModel: "",
        theCar: {},
        vehicleModelCode: "",
        fyon: ""
      };
    },
    methods: {
      tagContact() {
        _tracking.tag("INTERIOR_CLICK_CONTACTAR_" + this.carModel + "_" + this.carId);
      },
      setupTabs() {

        let carTabs = this.$refs.carDetail.querySelectorAll("ul.tab-block .tab-item");
        let carTabsContent = this.$refs.carDetail.querySelectorAll(".tab-content");

        const tabClick = (e, liSelected) => {

          e.preventDefault();

          for (var i = 0; i < carTabs.length; i++) {
            carTabs[i].classList.remove("active");
          }

          var clickedTab = liSelected;
          clickedTab.classList.add("active");

          for (let i = 0; i < carTabsContent.length; i++) {
            carTabsContent[i].classList.remove("active");
          }

          let activePane = this.$refs.carDetail.querySelector(`.tab-content[data-tab='${e.currentTarget.getAttribute("href")}']`);
          activePane.classList.add("active");

        };

        for (let i = 0; i < carTabs.length; i++) {
          carTabs[i].querySelector("a").addEventListener("click", (e) => {
            tabClick(e, carTabs[i]);
          });
        }

      },
      setupAccordions() {
        let carAccordions = this.$refs.carDetail.querySelectorAll(".accordion");
        for (var i = 0; i < carAccordions.length; i++) {
          initAccordion(carAccordions[i]);
        }
      },
      setupInfoExpandible() {
        let infoExp = this.$refs.carDetail.querySelectorAll(".data-secc-info div:first-child");
        for (let i = 0; i < infoExp.length; i++) {
          infoExp[i].addEventListener("click", () => {
            infoExp[i].parentNode.classList.toggle("active");
          });
        }
      },
      initGallery() {
        this.swiper = new Swiper(this.$refs.carDetail.querySelector('.swiper'), {
          slidesPerView: 1,
          effect: "fade",
          loop: true,
          on: {
            slideChange: (theSwiper) => {
              this.actualSlide = theSwiper.realIndex;
              this.updateSelected();
            },
            init: (theSwiper) => {
              this.actualSlide = theSwiper.realIndex;
              this.totalGallerySlides = theSwiper.slides.length - 2;
            }
          }
        });
      },
      updateSelected() {

        this.$imgPaginations = this.$el.querySelectorAll(".cardetail__gallery__selector li");

        if (this.$imgPaginations) {

          this.$imgPaginations.forEach((theSlide, index) => {

            if (this.actualSlide == index) {
              this.$imgPaginations.item(index).classList.add("active")
            }
            else {
              this.$imgPaginations.item(index).classList.remove("active")
            }
          });
        }
      },
      slidePrev() {
        if (this.swiper)
          this.swiper.slidePrev();
      },
      slideNext() {
        if (this.swiper)
          this.swiper.slideNext();
      },
      viewSlide(e) {
        let clickedLI = e.target.closest("li");
        if (this.swiper)
          this.swiper.slideTo(Array.from(clickedLI.parentNode.children).indexOf(clickedLI) + 1);
      },
      getDealerCode(name) {
        let code = "";

        document.cookie.split('; ').map((cookie) => {
          let key = cookie.split("=")[0]
          let value = cookie.split("=")[1]

          if (name === key) code = value
        })

        return code
      }
    },
    apollo: {

      stockCars: {
        query: GET_CAR_DETAIL,
        variables() {
          return {
            "filter": {
              "filter": {
                "value": {
                  "id": {
                    "value": [{
                      "value": this.$route.params.idCar
                    }]
                  },
                  "available": {
                    "value": [{
                      "value": true
                    }]
                  }
                }
              }
            },
            "locale": "es-ES"
          }
        },
        result({ data }) {


          if (!data) {
            console.log("GQL SERVER FAILED");
            setTimeout(() => {
              this.$apollo.queries.stockCars.refresh();
            }, 2000);
            return;
          }


          this.stockCars = data.stockCars;

          this.carId = this.stockCars.hits[0].vehicle.id;
          this.carModel = this.stockCars.hits[0].vehicle.model.code;
          this.theCar = this.stockCars.hits[0];
          this.colorCar = this.stockCars.hits[0].vehicle.configuration.color.description[1].text;

          this.vehicleModelCode = this.stockCars.hits[0].vehicle.model.code;
          this.fyon = this.stockCars.hits[0].vehicle.order.fyon;

          //con color group
          // this.colorCar = this.stockCars.hits[0].vehicle.configuration.color.code;
          // this.colorGroupCar = null;
          // this.$store.getters.colorGroups.forEach((element) =>{
          //   if (element.values.includes(this.colorCar)){
          //     this.colorGroupCar = element; 
          //   }
          // });




          let carName = this.stockCars.hits[0].vehicle.model.description[0].text;
          _tracking.tag("Page_Car_Details_Load_CAR_" + carName + "_" + this.carId);


          let groupsIds = [];
          let techGroups = {};
          let foundDetalle = false;


          this.stockCars.hits[0].vehicle.configuration.features.forEach((feature) => {
            foundDetalle = true;
            if (groupsIds.indexOf(feature.groupCode) === -1) {

              groupsIds.push(feature.groupCode);
              //console.log("gd",typeof feature.groupDescription[1]);
              if (typeof feature.groupDescription[1] === 'object') {
                techGroups[feature.groupCode] = {
                  name: feature.groupDescription[1].text,
                  elements: [feature]
                }
              }

            }
            else {
              techGroups[feature.groupCode].elements.push(feature);
            }
          });

          this.dataDetallesTecnicos = techGroups;

          if (foundDetalle) {
            this.dataDetallesTecnicos.enabled = true;
          }
          else {
            this.dataDetallesTecnicos.enabled = false;
          }







          //options
          let groupsOptionsIds = [];
          let optionGroups = {};
          let foundOpcion = false;

          this.stockCars.hits[0].vehicle.configuration.options.forEach((option) => {


            if (option.description[1].text && option.description[1].text.toLowerCase() != "unknown" && option.groupDescription[1].text && option.groupDescription[1].text.toLowerCase() != "unknown") {
              foundOpcion = true;
              if (groupsOptionsIds.indexOf(option.groupCode) === -1) {
                groupsOptionsIds.push(option.groupCode);
                optionGroups[option.groupCode] = {
                  name: option.groupDescription[1].text,
                  elements: [option]
                }
              }
              else {
                optionGroups[option.groupCode].elements.push(option);
              }
            }
          });

          this.dataDetallesOpciones = optionGroups;
          if (foundOpcion) {
            this.dataDetallesOpciones.enabled = true;
          }
          else {
            this.dataDetallesOpciones.enabled = false;
          }



          //packages
          let groupsPackagesIds = [];
          let packageGroups = {};
          let foundPackage = false;
          this.stockCars.hits[0].vehicle.configuration.packages.forEach((packagecfg) => {
            if (packagecfg.description[1].text && packagecfg.description[1].text.toLowerCase() != "unknown" && packagecfg.groupDescription[1].text && packagecfg.groupDescription[1].text.toLowerCase() != "unknown") {

              foundPackage = true;

              if (groupsPackagesIds.indexOf(packagecfg.groupCode) === -1) {
                groupsPackagesIds.push(packagecfg.groupCode);
                packageGroups[packagecfg.groupCode] = {
                  name: packagecfg.groupDescription[1].text,
                  elements: [packagecfg]
                }
              }
              else {
                packageGroups[packagecfg.groupCode].elements.push(packagecfg);
              }
            }
          });

          this.dataDetallesPackages = packageGroups;
          if (foundPackage) {
            this.dataDetallesPackages.enabled = true;
          }
          else {
            this.dataDetallesPackages.enabled = false;
          }





          // console.log(this.dataDetallesOpciones);
          // console.log("this.dataDetallesPackages")
          // console.log(this.dataDetallesPackages)





          // detallesTecnicos

          setTimeout(() => {
            this.setupTabs();
            this.setupAccordions();
            this.setupInfoExpandible();
            this.initGallery();
          }, 150);


        }
      }


    },
    beforeDestroy() {
      if (this.swiper)
        this.swiper.destroy();
    },
    mounted() {}
  };
</script>
