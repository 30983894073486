<template>
  <div class="home" :class="{'loading' : this.showLoading}" >
    <div class="lds-dual-ring"></div>
    <app-herohome></app-herohome>
    <app-headlineheader></app-headlineheader>
    <app-carsgridheader></app-carsgridheader>
    <section class="stock-box mx-auto max-2x">
      <app-gridfilter @filtersUpdated="onFiltersUpdated()" ref="compFilter"></app-gridfilter>
      <app-grid 
        @isLoadingChanged="updateLoadingState($event)"  
        @gridUpdated="refreshFilters()" 
        @modelkeyNoResults="onModelkeyNoResults()" 
        @tokenNoResults="onTokenNoResults()" 
        @tokenModeOnLoad="/*openFilters()*/" 
        @modelkeyModeOnLoad="openFilters()" 
        ref="compGrid" 
      ></app-grid>
    </section><!-- .stock-box -->
  </div>
</template>


<style lang="scss">
@import "@/scss/_responsive.scss";
@import "@/scss/_vars.scss";
.filters-mobile-trigger {  
  
}
</style>


<script>
// @ is an alias to /src
import HeroHome from '@/components/HeroHome'
import CarsGrid from '@/components/CarsGrid'
import HeadlineHeader from '@/components/HeadlineHeader'
import CarsGridFilter from '@/components/CarsGridFilter'
import CarsGridHeader from '@/components/CarsGridHeader'
import Tracking from '@/utils/Tracking';
let _tracking =  new Tracking();

export default {
  name: 'HomeView',
  
  methods: {
    refreshFilters: function() {
      this.$refs.compFilter.updateFilterConfig();
    },
    onFiltersUpdated: function() {
      this.$refs.compGrid.updateURL();
    },
    updateLoadingState: function(isLoading) {
      this.showLoading = isLoading;
    },
    onModelkeyNoResults:function() {
      this.$refs.compFilter.clearFilter("modelo", false);
      this.$refs.compFilter.clearFilter("traccion", false);
      this.$refs.compFilter.clearFilter("combustible", false);
      this.$refs.compFilter.clearFilter("color", false);
    },
    onTokenNoResults:function() {
      this.$refs.compFilter.clearFilter("modelo", false);
      this.$refs.compFilter.clearFilter("traccion", false);
      this.$refs.compFilter.clearFilter("combustible", false);
      this.$refs.compFilter.clearFilter("color", false);
    },
    openFilters: function() {
      this.$refs.compFilter.showBasicFilters();
    }
  },
  data(){
    return {
      showLoading: false
    }
  },
  components: {
    "app-herohome" : HeroHome,
    "app-headlineheader" : HeadlineHeader,
    "app-grid" : CarsGrid,
    "app-gridfilter" : CarsGridFilter,
    "app-carsgridheader" : CarsGridHeader
  },
  mounted ()   {
    document.body.classList.add("grid-mode");
  
    window.scrollTo(0, 0);

    setTimeout(()=>{
      _tracking.tag("Page_HOME_Load ");
  }, 500);
  
  }
}
</script>