const state = {
  modelo: [],
  filteredModeloAgg: null
};

const mutations = {
  TOGGLE_MODELO(state, payload) {
    if (!state.modelo.includes(payload)){
      state.modelo.push(payload);
    } else {
      state.modelo = state.modelo.filter(item => item !== payload)
    }
  },
  REMOVE_ALL_MODELO(state) {
    state.modelo = [];
  },
  UPDATE_FILTER_MODELOAGG(state, payload){
    state.filteredModeloAgg = payload;
  }

};

const actions = {
  toggleModelo: ({ commit }, payload) => {
    commit("TOGGLE_MODELO", payload);
  },
  deleteAllModelo : ({ commit }, payload) => {
    commit("REMOVE_ALL_MODELO", payload);
  },
  updateFilteredModeloAgg: ({ commit }, payload) => {
    commit("UPDATE_FILTER_MODELOAGG", payload);
  },
  // enableModelByName: ({ commit }, payload) => {
  //   console.log("enableModelByName " + payload);

  //   // commit("REMOVE_ALL_MODELO", payload);
  // }
};

const getters = {
  modelo: state => {
    return state.modelo;
  },
  getFilterModeloConfig: ( state ) => {
    let o = []; 
    for (let i = 0; i < state.modelo.length; i++){
      o.push({
        "value": state.modelo[i]
      });
    }
    return o;
  },
  getFilteredModeloAgg: ( state ) => {
    return state.filteredModeloAgg;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
  