<template>
  <section>
      <h2 class="show-md">Buscador de <br>vehículos de stock<sup>*</sup></h2>
      <h2 class="hide-md">Buscador de vehículos de stock<sup>*</sup></h2>
      <p>* Entrega en menos de 40 días. Sujeto a disponibilidad. Programa válido para Península y Baleares. En Canarias consulta por favor con tu concesionario.</p>
  </section>
</template>

<script>

export default {
  name: 'HeadlineHeader',
  props: {
  }
}
</script>

<style scoped lang="scss">

  @import "@/scss/_vars.scss";
  @import "@/scss/_responsive.scss";

  section {
    text-align: left;
    padding: $marginMobile;

    @include min-width("md") {
      text-align: center;    
      padding: 40px 0;
    }

    h2 {
      font-size: 25px;
      line-height: 1.25;
      color: $black;
      margin: 0 0 4px 0;
      font-weight: bold;
      
      @include min-width("md") {
        font-size: 32px;
      }

      sup {
        top: -0.2rem;
      }
    }

    p {
      color: $gray;
      font-size: 14px;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
</style>
