import Vue from "vue";
import Vuex from "vuex";

import traccion from "./modules/traccion";
import modelo from "./modules/modelo";
import combustible from "./modules/combustible";
import color from "./modules/color";
import precio from "./modules/precio";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dealerCode: "",
    elementosVisible: 12,
    paginationIndex: 0,
    initialConfigDone: false,
    initAggregations: [],
    isFiltersExpanded: true,
    isFiltersMobileExpanded: false,
    totalCarsHits: 0,
    filterByTokenEnabled: false,
    carsFilteredByToken: [],
    isFilteringByQuerie: false,
    isFilteringByModelkey: false,
    modelkeySelected: "",
    modelkeySearchResults: -1,
    userHasConfiguratorInfo: false,
    userConfiguratorInfo: {},
    isProdEnv: false,
    isLoading: false,
    lastWindowScrollY: 0,
    inOutYActive: false,
    useAlternativeServer: false,
    showWarningNoStockModelkey: false,
    showWarningNoStockToken: false,
  },
  getters: {
    dealerCode: (state) => state.dealerCode,
    elementosVisible: (state) => state.elementosVisible,
    paginationIndex: (state) => state.paginationIndex,
    initAggregations: (state) => state.initAggregations,
    initialConfigDone: (state) => state.initialConfigDone,
    getInitialAggregationByName: (state) => (idAggregation) => {
      return state.initAggregations.find((el) => el.name === idAggregation);
    },
    isFiltersExpanded: (state) => state.isFiltersExpanded,
    isFiltersMobileExpanded: (state) => state.isFiltersMobileExpanded,
    totalCarsHits: (state) => state.totalCarsHits,
    filterByTokenEnabled: (state) => state.filterByTokenEnabled,
    carsFilteredByToken: (state) => state.carsFilteredByToken,
    isFilteringByQuerie: (state) => state.isFilteringByQuerie,
    isFilteringByModelkey: (state) => state.isFilteringByModelkey,
    modelkeySelected: (state) => state.modelkeySelected,
    modelkeySearchResults: (state) => state.modelkeySearchResults,
    userHasConfiguratorInfo: (state) => state.userHasConfiguratorInfo,
    userConfiguratorInfo: (state) => state.userConfiguratorInfo,
    isProdEnv: (state) => state.userConfiguratorInfo,
    isLoading: (state) => state.isLoading,
    lastWindowScrollY: (state) => state.lastWindowScrollY,
    inOutYActive: (state) => state.inOutYActive,
    useAlternativeServer: (state) => state.useAlternativeServer,
    showWarningNoStockModelkey: (state) => state.showWarningNoStockModelkey,
    showWarningNoStockToken: (state) => state.showWarningNoStockToken,
  },
  mutations: {
    SET_DEALER_CODE(state, code) {
      state.dealerCode = code;
    },
    SET_NUMBER_ITEMS(state, n) {
      state.elementosVisible = n;
    },
    SET_INITIAL_AGGREGATIONS(state, aggregations) {
      state.initAggregations = aggregations;
    },
    RESET_PAGINATION_INDEX(state) {
      state.paginationIndex = 0;
    },
    NEXT_PAGINATION_INDEX(state) {
      state.paginationIndex++;
    },
    SET_INITIAL_CONFIG(state, payload) {
      state.initialConfigDone = payload;
    },
    SET_FILTERS_EXPANDED(state, payload) {
      state.isFiltersExpanded = payload;
    },
    SET_FILTERS_MOBILE_EXPANDED(state, payload) {
      state.isFiltersMobileExpanded = payload;
    },
    SET_TOTAL_CAR_HITS(state, payload) {
      state.totalCarsHits = payload;
    },
    SET_FILTER_BY_TOKEN(state, payload) {
      state.filterByTokenEnabled = payload;
      if (!payload) {
        state.carsFilteredByToken = [];
      }
    },
    SET_CARS_FILTERED_BY_TOKEN(state, payload) {
      state.carsFilteredByToken = payload;
    },
    SET_FILTER_BY_QUERY(state, payload) {
      state.isFilteringByQuerie = payload;
    },
    SET_FILTER_BY_MODELKEY(state, payload) {
      state.isFilteringByModelkey = payload;
    },
    SET_FILTER_BY_MODELKEY_MODEL(state, payload) {
      state.modelkeySelected = payload;
    },
    SET_MODELKEY_RESULTS(state, payload) {
      state.modelkeySearchResults = payload;
    },
    SET_USER_CONFIGURATION(state, payload) {
      state.userHasConfiguratorInfo = true;
      state.userConfiguratorInfo = payload;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_LAST_SCROLL_Y(state, payload) {
      state.lastWindowScrollY = payload;
    },
    SET_ANIM_OUT_Y(state, payload) {
      state.inOutYActive = payload;
    },
    SET_ALTERNATIVE_SERVER(state, payload) {
      state.useAlternativeServer = payload;
    },
    SET_WARNING_NOSTOCK_MODELKEY(state, payload) {
      state.showWarningNoStockModelkey = payload;
    },
    SET_WARNING_NOSTOCK_TOKEN(state, payload) {
      state.showWarningNoStockToken = payload;
      // console.log(state,payload)
    },
  },
  actions: {
    setDealerCode: ({ commit }, code) => {
      commit("SET_DEALER_CODE", code);
    },
    changeElementosVisibles: ({ commit }, n) => {
      commit("SET_NUMBER_ITEMS", n);
    },
    configInitialAgreggations: ({ commit }, payload) => {
      commit("SET_INITIAL_AGGREGATIONS", payload);
    },
    resetPaginationIndex: ({ commit }) => {
      commit("RESET_PAGINATION_INDEX");
    },
    nextPaginationIndex: ({ commit }) => {
      commit("NEXT_PAGINATION_INDEX");
    },
    setInitialConfig: ({ commit }, payload) => {
      commit("SET_INITIAL_CONFIG", payload);
    },
    setFiltersExpanded: ({ commit }, payload) => {
      commit("SET_FILTERS_EXPANDED", payload);
    },
    setFiltersMobileExpanded: ({ commit }, payload) => {
      commit("SET_FILTERS_MOBILE_EXPANDED", payload);
    },
    setTotalHits: ({ commit }, payload) => {
      commit("SET_TOTAL_CAR_HITS", payload);
    },
    setFilterByToken: ({ commit }, payload) => {
      commit("SET_FILTER_BY_TOKEN", payload);
    },
    setCarsFilteredByToken: ({ commit }, payload) => {
      commit("SET_CARS_FILTERED_BY_TOKEN", payload);
    },
    setFilterByQuery: ({ commit }, payload) => {
      commit("SET_FILTER_BY_QUERY", payload);
    },
    setFilterByModelkey: ({ commit }, payload) => {
      commit("SET_FILTER_BY_MODELKEY", payload);
    },
    setFilterByModelkeyModel: ({ commit }, payload) => {
      commit("SET_FILTER_BY_MODELKEY_MODEL", payload);
    },
    setModelkeyResults: ({ commit }, payload) => {
      commit("SET_MODELKEY_RESULTS", payload);
    },
    setModelkeyWarningNoStock: ({ commit }, payload) => {
      commit("SET_WARNING_NOSTOCK_MODELKEY", payload);
    },
    setTokenWarningNoStock: ({ commit }, payload) => {
      commit("SET_WARNING_NOSTOCK_TOKEN", payload);
    },
    setUserConfigurationInfo: ({ commit }, payload) => {
      commit("SET_USER_CONFIGURATION", payload);
    },
    setLoading: ({ commit }, payload) => {
      commit("SET_LOADING", payload);
    },
    setLastScrollY: ({ commit }, payload) => {
      commit("SET_LAST_SCROLL_Y", payload);
    },
    enableAnimacionVertical: ({ commit }, payload) => {
      commit("SET_ANIM_OUT_Y", payload);
    },
    setAlternativeServer: ({ commit }, payload) => {
      commit("SET_ALTERNATIVE_SERVER", payload);
    },
  },
  modules: {
    traccion,
    modelo,
    combustible,
    color,
    precio,
  },
});
